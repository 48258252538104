import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


export const initialState = {
  isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn")) || false,
  username: localStorage.getItem("username") || null,
  token: localStorage.getItem("token") || null,
  token_expiration: localStorage.getItem("token_expiration") || 0,
  refresh_token: localStorage.getItem("refresh_token") || null,
  refresh_token_expiration: localStorage.getItem("refresh_token_expiration") || 0,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID || "Iv1.72316a5393221e5b",
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI || "http://localhost:3000/login",
  PROXY_URL: process.env.REACT_APP_PROXY_URL || "http://127.0.0.1:8000/",
  LISTING_NAME: process.env.REACT_APP_LISTING_NAME || "precaution-test",
  BASIC_PLAN_ID: process.env.REACT_APP_BASIC_PLAN_ID || "MLP_kgDNIAk",
  PRO_PLAN_ID: process.env.REACT_APP_PRO_PLAN_ID || "MLP_kgDNIAo",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      localStorage.setItem("isLoggedIn", JSON.stringify(action.payload.isLoggedIn));
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("token_expiration", action.payload.token_expiration);
      localStorage.setItem("refresh_token", action.payload.refresh_token);
      localStorage.setItem("refresh_token_expiration", action.payload.refresh_token_expiration);

      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        username: action.payload.username,
        token: action.payload.token,
        token_expiration: action.payload.token_expiration,
        refresh_token: action.payload.refresh_token,
        refresh_token_expiration: action.payload.refresh_token_expiration,
      };
    }
    case "LOGOUT": {
      localStorage.clear()
      return {
        ...state,
        isLoggedIn: false,
        username: null,
        token: null,
        token_expiration: 0,
        refresh_token: null,
        refresh_token_expiration: 0,
      };
    }
    default:
      return state;
  }
};

ReactDOM.render(
  <App />,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
