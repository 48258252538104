import React, {
  Fragment,
  Suspense,
  lazy,
  createContext,
  useReducer,
} from "react";
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import { initialState, reducer } from "./";


const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));
const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));
export const AuthContext = createContext();


function RequireAuth({ children }) {
  const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
  return isLoggedIn === true ? children : <Redirect to="/login" />;
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider
      value={{ state, dispatch }}
    >
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            <Pace color={theme.palette.primary.light} />
            <Suspense fallback={<Fragment />}>
              <Switch>
                <Route path="/c">
                  <RequireAuth>
                    <LoggedInComponent />
                  </RequireAuth>
                </Route>
                <Route>
                  <LoggedOutComponent />
                </Route>
              </Switch>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
